import { useEffect, useState } from "react"
import moment from "moment"
import axios from "axios"

export const useActualTime = () => {
  const [time, setTime] = useState(moment())

  useEffect(() => {
    const intervalId = setInterval(() => {
      axios
        .get("https://timeapi.io/api/time/current/zone?timeZone=Asia%2FManila")
        .then((response) => {
          const data = response.data
          const momentTime = moment(data.dateTime)
          setTime(momentTime)
        })
    }, 5_000)

    return () => clearInterval(intervalId)
  }, [])

  return time
}
